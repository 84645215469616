import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteLinea, getPedidoActivo, nuevoPedido, setIdPedidoSeleccionado } from "../../actions/pedidos";
import { getPrecios } from "../../actions/precio";
import { cargarArrays, comprobarCondiciones, obtenerValorParametro } from "../../helpers/funciones";
import Imagen from "./Imagen";
import Precio from "./Precio";

let precioGrupal = "N";
let stockGrupal = "N";
let pedir_grupos = false;
let articulos_aux = [];

export class Carrito extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    setIdPedidoSeleccionado: PropTypes.func.isRequired,
    nuevoPedido: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
    cadenas_logisticas: PropTypes.object.isRequired,
  };

  componentDidMount() {
    precioGrupal = obtenerValorParametro(this.props.portal.parametros_ecom, "PRECIO_GRUPAL", "GEN");
  }

  componentDidUpdate(prevProps, prevState) {
    //Comprobamos si solicitan precios grupalmente
    if (comprobarCondiciones("carrito", this.props.pedido, prevProps.pedido, precioGrupal)) {
      articulos_aux = cargarArrays("carrito", this.props.pedido, "precios", this.props.precio);
      if (articulos_aux !== undefined && articulos_aux !== null && articulos_aux.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        this.props.getPrecios(
          articulos_aux, //articulos_cantidades
          "carrito", //origen
          null //domicilio_envio
        );
      }
    }
    if (this.props.pedido.lineas !== undefined && this.props.pedido.lineas !== null && this.props.pedido.lineas.length > 0) {
      for (let l = 0; l < this.props.pedido.lineas.length; l++) {
        let index = -1;
        let importe_neto_lin = 0;
        if (
          (this.props.pedido.lineas[l].importe_neto_lin === undefined ||
            this.props.pedido.lineas[l].importe_neto_lin === null ||
            this.props.pedido.lineas[l].importe_neto_lin === 0) &&
          this.props.pedido.lineas[l].precio_venta !== undefined &&
          this.props.pedido.lineas[l].precio_venta !== null &&
          this.props.pedido.lineas[l].precio_venta !== 0
        ) {
          if (this.props.infoPedido !== undefined && this.props.infoPedido !== null && this.props.infoPedido.length > 0) {
            for (let h = 0; h < this.props.infoPedido.length; h++) {
              if (this.props.infoPedido[h].codigo_articulo === this.props.pedido.lineas[l].articulo) {
                let precio_neto = Math.round((Number.parseFloat(this.props.pedido.lineas[l].precio_venta) + Number.EPSILON) * 10000) / 10000;
                importe_neto_lin = 0;
                if (
                  this.props.infoPedido[h].unidad_precio_venta === 1 ||
                  (this.props.infoPedido[h].unidad_precio_venta === 2 && this.props.infoPedido[h].unidad_codigo2 !== "KG")
                ) {
                  importe_neto_lin = precio_neto;
                } else if (this.props.infoPedido[h].unidad_precio_venta === 2 && this.props.infoPedido[h].unidad_codigo2 === "KG") {
                  importe_neto_lin = Number.parseFloat(this.props.infoPedido[h].peso_neto) * precio_neto;
                }
                importe_neto_lin = Math.round((Number.parseFloat(importe_neto_lin) + Number.EPSILON) * 10000) / 10000;
                this.props.pedido.lineas[l].importe_neto_lin = importe_neto_lin;
              }
            }
          } else {
            importe_neto_lin = Math.round((Number.parseFloat(importe_neto_lin) + Number.EPSILON) * 10000) / 10000;
            this.props.pedido.lineas[l].importe_neto_lin = importe_neto_lin;
          }
        }
      }
    }
  }

  onClick = (e) => {
    if (!this.props.pedido_cargando) {
      e.preventDefault();
      this.props.deleteLinea(e.target.dataset.linea, this.props.hash, this.props.id_pedido_seleccionado);
    }
  };

  ocultarCarrito = (e) => {
    if (document.getElementsByClassName("tt-dropdown-obj active").length > 0) {
      document.getElementsByClassName("tt-dropdown-obj active")[0].getElementsByClassName("tt-dropdown-menu")[0].style = "";

      document.getElementsByClassName("tt-dropdown-obj active")[0].classList.remove("active");
    }
    if (document.getElementsByTagName("body").length > 0) {
      document.getElementsByTagName("body")[0].classList.remove("tt-popup-dropdown");
    }
  };

  nuevoPedido = () => {
    this.props.setIdPedidoSeleccionado("");
    this.props.nuevoPedido();
    this.props.getPedidoActivo("", "S");
  };

  calcularDatosLinea = (linea) => {
    let unidades = null;
    let cantidad = null;
    let importe_neto = null;

    if (this.props.cadenas_logisticas !== undefined && this.props.cadenas_logisticas.length > 0) {
      let info_articulo = this.props.cadenas_logisticas.filter((e) => linea.articulo === e.codigo_articulo)[0];
      if (info_articulo) {
        unidades = Number.parseInt(linea.cantidad_pedida);
        cantidad = linea.cantidad_pedida;
        const precio_venta = Math.round((Number.parseFloat(linea.precio_venta) + Number.EPSILON) * 100) / 100;
        importe_neto = unidades * precio_venta;

        let cadena_logistica = info_articulo.cadena_logistica;

        if (cadena_logistica !== undefined && linea.presentacion_pedido === "CA") {
          unidades = parseInt(linea.cantidad_pedida) * parseInt(cadena_logistica.convers_u_exp);
          if (info_articulo.unidad_precio_venta === 2 && info_articulo.unidad_codigo2 === "KG") {
            cantidad = unidades * parseFloat(info_articulo.peso_neto);
            importe_neto = cantidad * precio_venta;
          } else {
            importe_neto = unidades * precio_venta;
            cantidad = unidades;
          }
        } else {
          if (info_articulo.unidad_precio_venta === 2 && info_articulo.unidad_codigo2 === "KG") {
            cantidad = parseInt(linea.cantidad_pedida) * parseFloat(info_articulo.peso_neto);
            importe_neto = cantidad * precio_venta;
          } else {
            importe_neto = linea.cantidad_pedida * precio_venta;
            cantidad = linea.cantidad_pedida;
          }
        }
        cantidad = Math.round((Number.parseFloat(cantidad) + Number.EPSILON) * 100) / 100;
      }
    } else if (this.props.id_pedido_seleccionado !== "") {
      this.props.getPedidoActivo("", "S", this.props.id_pedido_seleccionado);
    }
    return { unidades, cantidad, importe_neto };
  };

  render() {
    return (
      <div className="tt-dropdown-menu">
        <div className="tt-mobile-add">
          <h6 className="tt-title">CARRITO</h6>
          <button className="tt-close">Cerrar</button>
        </div>
        <div className="tt-dropdown-inner">
          <div className="tt-cart-layout">
            <div className="tt-cart-content">
              <div className="info-ped-pres">
                {this.props.pedido.lineas !== undefined &&
                this.props.pedido.lineas.length > 0 &&
                this.props.pedido.lineas.some((linea) => linea.tipo_linea === "P") ? (
                  <div className="info-pedido" style={{ maxHeight: "calc(100vh - 207px)" }}>
                    <div>
                      <h3 className="titulo-carrito">Pedido</h3>
                    </div>
                    <div className="tt-cart-list">
                      {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                        ? ""
                        : this.props.pedido.lineas
                            .filter((linea) => {
                              if (linea.tipo_linea === "P") {
                                return true;
                              } else if (linea.tipo_linea === "R") {
                                return this.props.pedido.lineas.some(
                                  (linea_padre) => (linea_padre.numero_linea = linea.numero_linea_origen && linea_padre.tipo_linea === "P")
                                );
                              } else return false;
                            })
                            .map((linea) => {
                              const { unidades, cantidad, importe_neto } = this.calcularDatosLinea(linea);

                              return (
                                <div className="tt-item" key={linea.id}>
                                  {
                                    <Fragment>
                                      <Link
                                        to={
                                          "/articulo/" +
                                          linea.articulo +
                                          "/" +
                                          linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()
                                        }
                                      >
                                        <div className="tt-item-img">
                                          <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                                        </div>
                                        <div className="tt-item-descriptions">
                                          <h2 className="tt-title">{linea.articulo}</h2>
                                          <ul className="tt-add-info">
                                            <li>{linea.descripcion}</li>
                                          </ul>
                                          <div className="tt-quantity">
                                            {linea.sub_pres !== undefined && linea.sub_pres !== null && linea.sub_pres !== ""
                                              ? linea.sub_pres_cant + " X " + linea.sub_pres + " X "
                                              : parseInt(linea.cantidad_pedida) + " (" + linea.presentacion_pedido + ") X "}
                                          </div>
                                          <div className="tt-price">
                                            {linea.tipo_linea !== "R" ? (
                                              linea.precio_manual === "S" ? (
                                                <Precio codigo_articulo={linea.articulo} precio_manual={linea.precio_venta} />
                                              ) : (
                                                <span>
                                                  {
                                                    <Precio
                                                      codigo_articulo={linea.articulo}
                                                      mostrar_iva={obtenerValorParametro(this.props.portal.parametros_ecom, "MOSTRAR_IVA", "RES")}
                                                      tipo_precio={obtenerValorParametro(this.props.portal.parametros_ecom, "TIPO_PRECIO", "RES")}
                                                      añadir_descuento={obtenerValorParametro(this.props.portal.parametros_ecom, "ANDR_DESC", "RES")}
                                                    />
                                                  }
                                                </span>
                                              )
                                            ) : (
                                              <Precio codigo_articulo={linea.articulo} precio_manual={0} />
                                            )}
                                          </div>
                                          <div className="carrito-importe_bruto">
                                            {linea.tipo_linea !== "R" ? (
                                              importe_neto !== undefined && importe_neto !== null ? (
                                                <Precio codigo_articulo={linea.articulo} precio_manual={Number.parseFloat(importe_neto)} />
                                              ) : (
                                                ""
                                              )
                                            ) : (
                                              <Precio codigo_articulo={linea.articulo} precio_manual={0} />
                                            )}
                                          </div>
                                        </div>
                                      </Link>

                                      {linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" ? (
                                        <div className="tt-item-close">
                                          <a href="/#" onClick={this.onClick} data-linea={linea.id} className="tt-btn-close">
                                            <span className="ocultar">Cerrar</span>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  }
                                </div>
                              );
                            })}
                    </div>

                    <div className="tt-cart-total-row">
                      <div className="tt-cart-total-title">TOTAL:</div>
                      <div className="tt-cart-total-price">
                        {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                          ? 0
                          : Intl.NumberFormat(this.props.locale, {
                              style: "currency",
                              currency: this.props.moneda,
                            }).format(
                              Number.parseFloat(
                                this.props.pedido.lineas
                                  .filter((linea) => linea.tipo_linea === "P")
                                  .map((linea) => {
                                    const { importe_neto } = this.calcularDatosLinea(linea);

                                    return Number.parseFloat(importe_neto ? importe_neto : 0);
                                  })
                                  .reduce((accumulator, currentValue) => accumulator + currentValue)
                              )
                            )}
                      </div>
                    </div>
                    <div className="tt-cart-total-row">
                      <div className="tt-cart-total-title">TOTAL + IVA:</div>
                      <div className="tt-cart-total-price">
                        {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                          ? 0
                          : new Intl.NumberFormat(this.props.locale, {
                              style: "currency",
                              currency: this.props.moneda,
                            }).format(
                              Number.parseFloat(
                                this.props.pedido.lineas
                                  .filter((linea) => linea.tipo_linea === "P")
                                  .map((linea) => {
                                    let impuesto_articulo = 0;
                                    if (
                                      this.props.precio[linea.articulo] !== undefined &&
                                      !Array.isArray(this.props.precio[linea.articulo].data.impuesto_articulo) &&
                                      this.props.precio[linea.articulo].data.impuesto_articulo !== null
                                    ) {
                                      impuesto_articulo = Number.parseInt(this.props.precio[linea.articulo].data.impuesto_articulo) / 100;
                                    }

                                    const { importe_neto } = this.calcularDatosLinea(linea);

                                    return Number.parseFloat(importe_neto) * (1 + impuesto_articulo);
                                  })
                                  .reduce((accumulator, currentValue) => {
                                    return accumulator + currentValue;
                                  })
                              ).toFixed(2)
                            )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.props.pedido.lineas !== undefined &&
                this.props.pedido.lineas.length > 0 &&
                this.props.pedido.lineas.some((linea) => linea.tipo_linea === "O") ? (
                  <div className="info-presupuesto">
                    <div>
                      <h3 className="titulo-carrito">Presupuesto</h3>
                    </div>
                    <div className="tt-cart-list">
                      {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                        ? ""
                        : this.props.pedido.lineas
                            .filter((linea) => {
                              if (linea.tipo_linea === "O") {
                                return true;
                              } else if (linea.tipo_linea === "R") {
                                return this.props.pedido.lineas.some(
                                  (linea_padre) => (linea_padre.numero_linea = linea.numero_linea_origen && linea_padre.tipo_linea === "O")
                                );
                              } else return false;
                            })
                            .map((linea) => {
                              const { importe_neto } = this.calcularDatosLinea(linea);

                              return (
                                <div className="tt-item" key={linea.id}>
                                  <Fragment>
                                    <Link
                                      to={
                                        "/articulo/" +
                                        linea.articulo +
                                        "/" +
                                        linea.descripcion.replaceAll("/", " ").replaceAll(" ", "-").replaceAll("%", "").toLowerCase()
                                      }
                                    >
                                      <div className="tt-item-img">
                                        <Imagen codigo_articulo={linea.articulo} principal="S" numero_imagen={1} />
                                      </div>
                                      <div className="tt-item-descriptions">
                                        <h2 className="tt-title">{linea.articulo}</h2>
                                        <ul className="tt-add-info">
                                          <li>{linea.descripcion}</li>
                                        </ul>
                                        <div className="tt-quantity">
                                          {linea.sub_pres !== undefined && linea.sub_pres !== null && linea.sub_pres !== ""
                                            ? linea.sub_pres_cant + " X " + linea.sub_pres + " X "
                                            : parseInt(linea.cantidad_pedida) + " (" + linea.presentacion_pedido + ") X "}
                                        </div>
                                        <div className="tt-price">
                                          {linea.tipo_linea !== "R" ? (
                                            linea.precio_manual === "S" ? (
                                              <Precio codigo_articulo={linea.articulo} precio_manual={linea.precio_venta} mostrar_iva={"N"} />
                                            ) : (
                                              <span>{<Precio codigo_articulo={linea.articulo} mostrar_iva={"N"} />}</span>
                                            )
                                          ) : (
                                            <Precio codigo_articulo={linea.articulo} precio_manual={0} mostrar_iva={"N"} />
                                          )}
                                        </div>
                                        <div className="carrito-importe_bruto">
                                          {new Intl.NumberFormat(this.props.locale, {
                                            style: "currency",
                                            currency: this.props.moneda,
                                          }).format(importe_neto ? importe_neto : 0)}
                                        </div>
                                      </div>
                                    </Link>

                                    <div className="tt-item-close">
                                      <a href="/#" onClick={this.onClick} data-linea={linea.id} className="tt-btn-close">
                                        <span className="ocultar">Cerrar</span>
                                      </a>
                                    </div>
                                  </Fragment>
                                </div>
                              );
                            })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="tt-cart-btn">
                <div className="tt-item">
                  {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0 ? (
                    "No hay articulos en el carrito"
                  ) : (
                    <Fragment>
                      {this.props.invitado !== undefined && this.props.invitado === true ? (
                        <Link to="/login" className="btn" onClick={this.ocultarCarrito}>
                          LOGIN
                        </Link>
                      ) : (
                        <Fragment>
                          {!this.props.pedido_cargando ? (
                            <Link to="/resumen" className="btn" onClick={this.ocultarCarrito}>
                              FINALIZAR CARRITO
                            </Link>
                          ) : (
                            ""
                          )}

                          <button type="submit" onClick={this.nuevoPedido} className="btn btn-primary btn-nuevo-pedido">
                            VACIAR CARRITO
                          </button>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  articulos: state.listadoArticulos.articulos,
  infoPedido: state.listadoArticulos.pedido.articulos,
  pedido: state.pedidos.pedido,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  precio: state.precio.precio,
  pedido_cargando: state.pedidos.isLoading,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  cadenas_logisticas: state.listadoArticulos.pedido.articulos,
});

export default connect(mapStateToProps, {
  deleteLinea,
  setIdPedidoSeleccionado,
  nuevoPedido,
  getPedidoActivo,
  getPrecios,
})(Carrito);
