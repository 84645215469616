import axios from "../axiosConfig";

import { FETCH_FESTIVOS, GET_FESTIVOS, GET_FESTIVOS_FAIL } from "./types";
import { tokenConfig } from "./auth";

// GET AVISOS
export const getFestivos = (
  desde_fecha = ""
) => (dispatch, getState) => {
  dispatch({
    type: FETCH_FESTIVOS
  });

  axios
    .get(
      `/festivosgenerales/?desde_fecha=${desde_fecha}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_FESTIVOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_FESTIVOS_FAIL
      });
    });
};