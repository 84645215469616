import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getArticulos,
  setBusqueda,
  getArticulosByFamily,
} from "../../actions/articulos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import AnalyticsEdisa from "../common/AnalyticsEdisa";

import { MagnifyingGlass } from "phosphor-react";
import { limpiarFiltros } from "../../actions/filtros";

export class Buscador extends Component {
  state = {
    valor: "",
  };

  static propTypes = {
    articulos_pagina: PropTypes.number.isRequired,
    articulos_orden: PropTypes.string.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    auth: PropTypes.object,
    getArticulos: PropTypes.func.isRequired,
    getArticulosByFamily: PropTypes.func.isRequired,
    limpiarFiltros: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.limpiarFiltros();

    const { valor } = this.state;
    let analytics = new AnalyticsEdisa();
    analytics.registraEvento({
      modulo: "Articulos",
      tipo: "Search",
      valor: valor,
    });

    this.props.setBusqueda(valor);

    if (this.props.filtros_aplicados.length > 0) {
      this.props.getArticulosByFamily(
        this.props.filtros_aplicados,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        valor,
        this.props.pedido.domicilio_envio !== undefined
          ? this.props.pedido.domicilio_envio
          : ""
      );
    } else {
      this.props.getArticulos(
        valor,
        this.props.articulos_pagina,
        this.props.articulos_orden,
        this.props.pedido.domicilio_envio !== undefined
          ? this.props.pedido.domicilio_envio
          : ""
      );
    }

    this.setState({
      valor: "",
    });

    if (this.props.history.location.pathname !== "/articulos") {
      this.props.history.push("/articulos");
    }
  };

  render() {
    const { valor } = this.state;
    const path = window.location.pathname;
    if(path === '/bienvenida' || path === '/registro' || path === '/login') return null;
    return (
      <div className="buscador justify-content-end">
        <form
          onSubmit={this.onSubmit}
          className="form-inline form-default justify-content-end"
        >
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              name="valor"
              placeholder="¿Qué producto buscas?"
              onChange={this.onChange}
              value={valor}
            />
            <div className="input-group-append">
              <button type="submit" className="btn btn-primary">
                <MagnifyingGlass color="#A68361" size={20} />
              </button>
            </div>
          </div>
          {this.props.mostrar_avanzado === "S" ? (
            <div className="enlace-busqueda-avanzada">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setBusqueda("");
                  this.props.cambiarBuscador();
                }}
                href="#"
              >
                Búsqueda avanzada
              </a>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicados: state.articulos.filtros_aplicados,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  pedido: state.pedidos.pedido,
});

export default withRouter(
  connect(mapStateToProps, { getArticulos, setBusqueda, getArticulosByFamily, limpiarFiltros })(
    Buscador
  )
);
