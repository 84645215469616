import React, { Component } from "react";
import { connect } from "react-redux";

export class Spinner extends Component {
  render() {
    if (
      this.props.showSpinner !== undefined &&
      this.props.showSpinner === true
    ) {
      return (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Spinner);
