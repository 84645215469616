import {
  GET_PRECIO,
  GET_PRECIO_SUCCESS,
  GET_PRECIO_FAIL,
  GET_PRECIOS,
  GET_PRECIOS_SUCCESS,
  GET_PRECIOS_FAIL,
  LIMPIAR_PRECIOS,
} from "../actions/types.js";

const initialState = {
  precio: {},
  locale: "es-ES",
  moneda: "EUR",
};
let locale = "";
let moneda = "";
let i = 0;

export default function reducer(state = initialState, action) {
  let lista_precios;
  switch (action.type) {
    case LIMPIAR_PRECIOS:
      return {
        ...state,
        precio: {},
      };

    case GET_PRECIO:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      lista_precios[action.codigo_articulo] = {
        data: {},
        isLoading: true,
        error: false,
      };
      return {
        ...state,
        precio: lista_precios,
      };

    case GET_PRECIO_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      lista_precios[action.codigo_articulo].data = {};
      lista_precios[action.codigo_articulo].isLoading = false;
      lista_precios[action.codigo_articulo].error = true;
      return {
        ...state,
        precio: lista_precios,
      };
    case GET_PRECIO_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      lista_precios[action.codigo_articulo].data = action.payload;
      lista_precios[action.codigo_articulo].isLoading = false;
      lista_precios[action.codigo_articulo].error = false;

      locale = "";
      switch (lista_precios[action.codigo_articulo].data.divisa_precio) {
        case "COP":
          locale = "es-CO";
          break;

        case "EUR":
          locale = "es-ES";
          break;

        case "BOP":
          locale = "es-BO";
          break;

        case "USD":
          locale = "en-US";
          break;

        case "DOP":
          locale = "es-DO";
          break;

        case "GBP":
          locale = "en-GB";
          break;
        default:
          locale = "es-ES";
      }

      return {
        ...state,
        precio: lista_precios,
        locale: locale,
        moneda: lista_precios[action.codigo_articulo].data.divisa_precio,
      };

    case GET_PRECIOS:
      lista_precios = JSON.parse(JSON.stringify(state.precio));
      for (i = 0; i < action.articulos_cantidades.length; i++) {
        lista_precios[action.articulos_cantidades[i].codigo_articulo] = {
          data: {},
          isLoading: true,
          error: false,
        };
      }
      return {
        ...state,
        precio: lista_precios,
      };

    case GET_PRECIOS_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio));
      for (i = 0; i < action.articulos_cantidades.length; i++) {
        lista_precios[action.articulos_cantidades[i].codigo_articulo] = {
          data: {},
          isLoading: false,
          error: true,
        };
      }
      switch (locale) {
        case "es-CO":
          moneda = "COP";
          break;

        case "es-ES":
          moneda = "EUR";
          break;

        case "es-BO":
          moneda = "BOP";
          break;

        case "en-US":
          moneda = "USD";
          break;

        case "es-DO":
          moneda = "DOP";
          break;

        case "en-GB":
          moneda = "GBP";
          break;
        default:
          moneda = "EUR";
          if (locale === ""){
            locale = "es-ES"
          }
          break;
      }
      return {
        ...state,
        precio: lista_precios,
        locale: locale,
        moneda: moneda
      };

    case GET_PRECIOS_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      locale = "";
      if (
        typeof action.payload.articulos === "object" &&
        !Array.isArray(action.payload.articulos)
      ) {
        lista_precios[action.payload.articulos.articulo.codigo_articulo] = {
          data: action.payload.articulos.articulo,
          isLoading: false,
          error: false,
        };
      } else {
        for (let i = 0; i < action.payload.articulos.length; i++) {
          lista_precios[action.payload.articulos[i].codigo_articulo] = {
            data: action.payload.articulos[i],
            isLoading: false,
            error: false,
          };
        }
      }
      switch (
        lista_precios[action.articulos_cantidades[0].codigo_articulo].data
          .divisa_precio
      ) {
        case "COP":
          locale = "es-CO";
          moneda = "COP";
          break;

        case "EUR":
          locale = "es-ES";
          moneda = "EUR";
          break;

        case "BOP":
          locale = "es-BO";
          moneda = "BOP";
          break;

        case "USD":
          locale = "en-US";
          moneda = "USD";
          break;

        case "DOP":
          locale = "es-DO";
          moneda = "DOP";
          break;

        case "GBP":
          locale = "en-GB";
          moneda = "GBP";
          break;
        default:
          locale = "es-ES";
          moneda = "EUR";
          break;
      }
      return {
        ...state,
        precio: lista_precios,
        locale: locale,
        moneda: moneda,
      };

    default:
      return state;
  }
}
