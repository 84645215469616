import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getFestivos } from "../../../actions/festivos";
import { setFechaEntregaPedido } from "../../../actions/pedidos";
import { getRutasDomicilio } from "../../../actions/rutas";

import { confirmAlert } from "react-confirm-alert";

import config from "../../../config";

import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);

let fs = false;
let rdc = false;

const ops_fecha = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export class FechaEntrega extends Component {
  state = {
    fecha_seleccionada: null,
    rutas_domicilio_cargadas: false,
    semanas_limite: 4,
    hay_ruta_seleccionada: false,
    hay_domicilio_seleccionado: false,
  };

  static propTypes = {
    pedido: PropTypes.object.isRequired,
    setFechaEntregaPedido: PropTypes.func.isRequired,
    pedido_cargando: PropTypes.bool.isRequired,
    ruta: PropTypes.object.isRequired,
    rutas: PropTypes.array.isRequired,
    domicilio: PropTypes.object.isRequired,
    getFestivos: PropTypes.func.isRequired,
    festivos: PropTypes.array.isRequired,
  };

  componentDidMount() {
    const hoy = new Date();

    if (this.props.festivos !== undefined && this.props.festivos.length === 0) {
      this.props.getFestivos();
    }
    if (this.props.modo !== "aviso" && this.props.pedido.fecha_entrega && this.state.fecha_seleccionada === null && hoy < this.props.pedido.fecha_entrega) {
      console.warn("El pedido YA TIENE FECHA", this.props.pedido.fecha_entrega);

      this.setState({
        ...this.state,
        fecha_seleccionada: Date.parse(this.props.pedido.fecha_entrega),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const hoy = new Date();

    if (this.props.modo !== "aviso" && this.props.pedido.fecha_entrega && this.state.fecha_seleccionada === null && hoy < this.props.pedido.fecha_entrega) {
      console.warn("El pedido YA TIENE FECHA", this.props.pedido.fecha_entrega);
      this.setState({
        ...this.state,
        fecha_seleccionada: this.parseISOString(this.props.pedido.fecha_entrega),
      });
    } else if (!this.compararObjetos(prevProps.ruta, this.props.ruta) || !this.compararObjetos(prevProps.domicilio, this.props.domicilio)) {
      const hay_ruta_seleccionada =
        this.props.ruta !== undefined && Object.getPrototypeOf(this.props.ruta) === Object.prototype && Object.keys(this.props.ruta).length > 0;
      const hay_domicilio_seleccionado =
        this.props.domicilio !== undefined && Object.getPrototypeOf(this.props.domicilio) === Object.prototype && Object.keys(this.props.domicilio).length > 0;

      if (this.props.modo !== "aviso") {

        this.setState({
          ...this.state,
          fecha_seleccionada: null,
          rutas_domicilio_cargadas: false,
          hay_ruta_seleccionada: hay_ruta_seleccionada,
          hay_domicilio_seleccionado: hay_domicilio_seleccionado,
        });

        fs = false;

        this.props.setFechaEntregaPedido(null);
      }

      // Si no se ha seleccionado una ruta => se ha seleccionado un domicilio => cargamos las rutas de ese domicilio
      if (!hay_ruta_seleccionada && hay_domicilio_seleccionado && !this.state.rutas_domicilio_cargadas && !rdc) {
        rdc = true;
        this.props.getRutasDomicilio(this.props.domicilio.numero_direccion);

        this.setState({
          ...this.state,
          rutas_domicilio_cargadas: true,
        });
      }

      if (this.props.modo !== "aviso") {
        //Forzamos la carga de la fecha seleccionada
        let fechaFin = new Date();
        fechaFin.setMonth(fechaFin.getMonth() + 3);
        for (var d = new Date(); d <= fechaFin; d.setDate(d.getDate() + 1)) {
          if (this.tieneRuta(d)) break;
        }
      }
    }
  }

  compararObjetos = (objeto1, objeto2) => {
    // Obtenemos las claves de ambos objetos
    const clavesObjeto1 = Object.keys(objeto1);
    const clavesObjeto2 = Object.keys(objeto2);

    // Comprobamos si tienen la misma cantidad de claves
    if (clavesObjeto1.length !== clavesObjeto2.length) {
      return false;
    }

    // Iteramos sobre las claves del primer objeto
    for (let i = 0; i < clavesObjeto1.length; i++) {
      const clave = clavesObjeto1[i];

      // Comprobamos si la clave actual existe en el segundo objeto
      if (!objeto2.hasOwnProperty(clave)) {
        return false;
      }

      // Comparamos los valores de las claves
      if (objeto1[clave] !== objeto2[clave]) {
        return false;
      }
    }

    // Si llegamos hasta aquí, los objetos son iguales
    return true;
  };

  parseISOString = (s) => {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  };

  seleccionarFecha = (fecha) => {
    if (fecha !== undefined && fecha !== null) {
      this.setState({
        ...this.state,
        fecha_seleccionada: fecha,
      });
      this.props.setFechaEntregaPedido(fecha.toISOString());
    }
  };

  compruebaDia = (fecha, ruta) => {
    // Comprobamos si es festivo
    let es_festivo = false;
    const f_string = fecha.toISOString().split("T")[0];
    if (this.props.festivos !== undefined && this.props.festivos.length > 0) {
      const festivo = this.props.festivos.find((f) => f.fecha === f_string);
      if (festivo !== undefined) es_festivo = true;
    }

    let tiene_ruta = false;
    const dia = fecha.getDay();
    if (dia === 0 && ruta.domingo === "S") tiene_ruta = true;
    if (dia === 1 && ruta.lunes === "S") tiene_ruta = true;
    if (dia === 2 && ruta.martes === "S") tiene_ruta = true;
    if (dia === 3 && ruta.miercoles === "S") tiene_ruta = true;
    if (dia === 4 && ruta.jueves === "S") tiene_ruta = true;
    if (dia === 5 && ruta.viernes === "S") tiene_ruta = true;
    if (dia === 6 && ruta.sabado === "S") tiene_ruta = true;

    if (ruta.excepciones !== undefined && ruta.excepciones.length > 0) {
      // Comprueba si ese día que tiene ruta hay alguna excepción
      const excepcion = ruta.excepciones.find((e) => e.fecha === f_string);
      if (excepcion !== undefined) return false;

      // Comprueba si la fecha está dentro de alguna excepción para proponerla
      const propuesta = ruta.excepciones.find((e) => e.fecha_propuesta === f_string);
      if (propuesta !== undefined && propuesta !== null && propuesta !== "") tiene_ruta = true;
    }

    if (tiene_ruta && !fs) {
      fs = true;
      this.seleccionarFecha(fecha);
      if (es_festivo && this.props.modo !== "texto" && this.props.modo !== "aviso") {
        confirmAlert({
          title: "DÍA FESTIVO",
          message: `Su día más cercano de entrega, ${fecha.toLocaleDateString(
            "es-ES",
            ops_fecha
          )} es festivo. Se le entregará la mercancía el primer día hábil disponible. Si lo desea puede seleccionar otro día en el calendario.`,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: "De acuerdo",
            },
          ],
        });
      }
    }
    if (es_festivo) return false;

    return tiene_ruta;
  };

  tieneRuta = (fecha) => {
    const manana = new Date();
    manana.setDate(manana.getDate() + 1);

    // Misma hora, minutos y segundos para los cálculos
    fecha.setHours(manana.getHours());
    fecha.setMinutes(manana.getMinutes());
    fecha.setSeconds(manana.getSeconds());
    fecha.setMilliseconds(manana.getMilliseconds());

    if (fecha < manana) return false;

    // Si son la misma fecha comprobamos la hora
    const ahora = new Date();
    if (fecha.getTime() === manana.getTime() && config.hora_limite_entrega_dia_siguiente !== undefined) {
      if (parseInt(ahora.getHours()) > parseInt(config.hora_limite_entrega_dia_siguiente)) {
        return false;
      }
    }

    //Solo mostramos el número de semanas que dice el estado
    const limiteSemanas = new Date();
    limiteSemanas.setDate(ahora.getDate() + this.state.semanas_limite * 7);
    if (fecha > limiteSemanas) return false;

    if (
      this.props.domicilio !== undefined &&
      Object.keys(this.props.domicilio).length > 0 &&
      Object.getPrototypeOf(this.props.domicilio) === Object.prototype
    ) {
      if (this.props.rutas !== undefined && this.props.rutas.length > 0) {
        let tiene_ruta = false;
        this.props.rutas.forEach((ruta) => {
          if (this.compruebaDia(fecha, ruta)) tiene_ruta = true;
        });
        return tiene_ruta;
      }
    } else if (this.props.ruta !== undefined && Object.getPrototypeOf(this.props.ruta) === Object.prototype && Object.keys(this.props.ruta).length > 0) {
      return this.compruebaDia(fecha, this.props.ruta);
    } else if (this.props.rutas && this.props.rutas.length > 0) {
      return this.compruebaDia(fecha, this.props.rutas[0]);
    }
    return false;
  };

  getMensajeAviso = () => {
    const ahora = new Date();

    const hora_limite = config.horas_limite[ahora.getDay()];

    if (ahora.getHours() < hora_limite) {
      if (this.props.rutas !== undefined && this.props.rutas.length > 0) {
        const hoy = ahora.getDay();
        const dia_ruta = hoy >= 5 ? 1 : hoy + 1;

        const rutas = this.props.rutas.filter((r) => {
          switch (dia_ruta) {
            case 1:
              return r.lunes === "S";
            case 2:
              return r.martes === "S";
            case 3:
              return r.miercoles === "S";
            case 4:
              return r.jueves === "S";
            case 5:
              return r.viernes === "S";
            case 6:
              return r.sabado === "S";
            case 0:
              return r.domingo === "S";
            default:
              return false;
          }
        });

        for (let i = 0; i < rutas.length; i++) {
          let ruta = rutas[i];
          if (ruta !== undefined && Object.getPrototypeOf(ruta) === Object.prototype && Object.keys(ruta).length > 0) {
            return hoy !== 5 ? config.mensaje_limite_entrega_dia_siguiente : config.mensaje_limite_entrega_viernes;
          }
        }
      }
    }
    return "";
  };

  render() {
    const hoy = new Date();
    return (
      <Fragment>
        <div className="header-aviso-pedido" style={this.props.modo === undefined || this.props.modo !== "aviso" ? { display: "none" } : {}}>
          {this.state.fecha_seleccionada !== null && this.props.modo === "aviso" ? this.getMensajeAviso() : ""}
        </div>
        <div className="tt-shopcart-box fecha_de_entrega" style={this.props.modo === undefined || this.props.modo === "aviso" ? { display: "none" } : {}}>
          <h4 className="tt-title">Fecha de entrega</h4>
          <div style={this.props.modo === undefined || this.props.modo !== "texto" ? { display: "none" } : {}}>
            <div>
              {this.state.fecha_seleccionada !== null && this.props.modo !== "aviso" && this.state.fecha_seleccionada instanceof Date
                ? `La fecha de entrega del pedido será el ${this.state.fecha_seleccionada.toLocaleDateString("es-ES", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}.`
                : "Calculando fecha de entrega..."}
            </div>
          </div>
          <div style={this.props.modo !== undefined && this.props.modo === "texto" ? { display: "none" } : {}}>
            <p>Seleccione la fecha de entrega</p>
            <div className="fecha_de_entrega_container">
              <DatePicker
                selected={this.state.fecha_seleccionada}
                onSelect={this.seleccionarFecha}
                startDate={hoy}
                locale="es"
                todayButton="Hoy"
                filterDate={this.tieneRuta}
                disabled={this.props.pedido_cargando || this.props.pedidoGuardado ? true : false}
                inline
              />
              <div className="fecha_de_entrega_excepciones">
                <h5>Avisos</h5>
                <ul>
                  {this.state.hay_ruta_seleccionada && this.props.ruta.excepciones !== undefined
                    ? this.props.ruta.excepciones.map((e) => {
                        const f = new Date(e.fecha);
                        return (
                          <li key={e.id + Math.random()}>
                            <span className="excepcion_fecha">{f.toLocaleDateString("es-ES", ops_fecha)}</span>:
                            <span className="excepcion_mensaje">{e.mensaje}</span>
                          </li>
                        );
                      })
                    : ""}
                  {this.state.hay_domicilio_seleccionado && this.props.rutas !== undefined
                    ? this.props.rutas.map((r) =>
                        r.excepciones.map((e) => {
                          const f = new Date(e.fecha);
                          return (
                            <li key={e.id + Math.random()}>
                              <span className="excepcion_fecha">{f.toLocaleDateString("es-ES", ops_fecha)}</span>:
                              <span className="excepcion_mensaje">{e.mensaje}</span>
                            </li>
                          );
                        })
                      )
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido_cargando: state.pedidos.isLoading,
  pedido: state.pedidos.pedido,
  ruta: state.rutas.ruta,
  rutas: state.rutas.rutas,
  domicilio: state.domiciliosEnvio.domicilio,
  festivos: state.festivos.festivos,
});

export default connect(mapStateToProps, {
  setFechaEntregaPedido,
  getRutasDomicilio,
  getFestivos,
})(FechaEntrega);
