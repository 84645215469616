import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  postLineaLista,
  getListasCompra,
  postLineasLista,
  crearLista
} from "../../actions/listasCompra";
import { closeModal } from "../../actions/modalGenerica";
import ReactModal from "react-modal";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import "../../css/modal.css";

ReactModal.setAppElement("#root");

export class ModalGenerica extends Component {
  state = {
    lista_seleccionada: "",
    nueva_lista: false,
    nombre_lista: ""
  };

  static propTypes = {
    postLineaLista: PropTypes.func.isRequired,
    postLineasLista: PropTypes.func.isRequired,

    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    codigo_articulo: PropTypes.string.isRequired,
    lineas_carrito: PropTypes.array.isRequired,
    listas_compra: PropTypes.array.isRequired,
    menus: PropTypes.array.isRequired,

    crearLista: PropTypes.func.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();

    if(this.state.nueva_lista){
      // Crear nueva lista
      if (this.state.nombre_lista !== "") {
        this.props.crearLista(this.state.nombre_lista);
        this.setState({ nombre_lista: "" });
        this.setState({ nueva_lista: false });
        return;
      }
    }

    if (
      this.props.codigo_articulo !== undefined &&
      this.props.codigo_articulo !== null &&
      this.props.codigo_articulo !== ""
    ) {
      const linea = {
        codigo_lista: this.state.lista_seleccionada,
        codigo_articulo: this.props.codigo_articulo,
      };

      this.props.postLineaLista(linea,"",this.props.id_pedido_seleccionado);

      let analytics = new AnalyticsEdisa();

      analytics.registraEvento({
        modulo: "Articulos",
        tipo: "AddToWishlist",
        valor: this.props.codigo_articulo,
        params: {
          codigo_articulo: this.props.codigo_articulo,
        },
      });
    } else if (
      this.props.lineas_carrito !== undefined &&
      this.props.lineas_carrito.length > 0
    ) {
      let lineas = [];

      this.props.lineas_carrito.forEach((linea_carrito) => {
        let lin = {
          codigo_lista: this.state.lista_seleccionada,
          codigo_articulo: linea_carrito.articulo,
          cantidad: linea_carrito.cantidad_pedida,
          cantidad_pedida: Number.parseFloat(linea_carrito.cantidad_pedida),
          presentacion_pedido: linea_carrito.presentacion_pedido,
          observaciones: linea_carrito.observaciones,
          sub_pres: linea_carrito.sub_pres,
          sub_pres_cant:
            linea_carrito.sub_pres_cant !== ""
              ? Number.parseInt(linea_carrito.sub_pres_cant)
              : linea_carrito.sub_pres_cant,
          sub_pres_fc:
            linea_carrito.sub_pres_fc !== ""
              ? Number.parseFloat(linea_carrito.sub_pres_fc)
              : "",
          pres_fc:
            linea_carrito.pres_fc !== ""
              ? Number.parseFloat(linea_carrito.pres_fc)
              : "",
          presentacion_escogida:
            linea_carrito.presentacion_escogida !== ""
              ? linea_carrito.presentacion_escogida
              : "",
        };
        lineas.push(lin);
      });

      this.props.postLineasLista(lineas,"",this.props.id_pedido_seleccionado);
    }

    this.props.closeModal();
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  cerrarModal = (e) => {
    e.preventDefault();

    this.setState({ nueva_lista: false });

    this.props.closeModal();
  };

  modalNuevaLista = (e) => {
    e.preventDefault();
    this.setState({ nueva_lista: true });
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    let findPos = (elem) => elem.nombre === "LISTAS";
    let findPos2 = (elem) => elem.programa === "listasCompra";
    let pos;
    let pos2;
    let pos3;
    if (this.props.menus !== undefined) {
      // BUSCAMOS SI ESTÁ EN EL MENÚ PRINCIPAL
      // Y NO ES PARTE DEL SUBMENÚ
      pos3 = this.props.menus.findIndex(findPos2);
      if(pos3 !== -1) {
        pos2 = pos3;
      } else {
        pos = this.props.menus.findIndex(findPos);
        if (pos !== -1) {
          pos2 = this.props.menus[pos].hijos.findIndex(findPos2);
        } else {
          pos2 = -1;
        }
      }

    } else {
      pos = -1;
      pos2 = -1;
    }

    if (
      pos2 !== -1 &&
      (this.props.codigo_articulo !== undefined ||
        this.props.lineas_carrito !== undefined)
    ) {
      return (
        <Fragment>
          <ReactModal
            isOpen={this.props.isOpen}
            onRequestClose={this.cerrarModal}
            style={customStyles}
          >
            <div>
              <div>
                <div>
                  <div>
                    {this.state.nueva_lista ? <h5>Nueva lista</h5> : <h5>Añadir a lista</h5>}
                  </div>
                  <div>
                    <form id="anadirLista" onSubmit={this.onSubmit}>
                      <div className="form-group">
                        {!this.state.nueva_lista ?
                          <>
                          <label htmlFor="lista_compra">Lista</label>
                          <select
                            class="form-control"
                            id="lista_compra"
                            name="lista_seleccionada"
                            onChange={this.onChange}
                          >
                            <option value="">Selecciona lista</option>
                            {this.props.listas_compra !== undefined
                              ? this.props.listas_compra.map((lista, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={lista.codigo_lista}
                                    >
                                      {lista.nombre_lista}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                          </> :
                          <div class="form-group">
                          <label for="nombre_lista">Nueva lista</label>
                          <input
                            type="text"
                            className="form-control"
                            id="nombre_lista"
                            aria-describedby="nombre_lista_help"
                            placeholder="Nombre de la lista"
                            onChange={this.onChange}
                            name="nombre_lista"
                          />
                        </div>
                          }
                      </div>
                    </form>
                  </div>
                  <div>
                    <button
                      type="submit"
                      form="anadirLista"
                      class="btn btn-primary"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      onClick={this.cerrarModal}
                    >
                      Cerrar
                    </button>
                    {!this.state.nueva_lista ?
                      <div style={{width: '100%',display: 'flex',justifyContent: 'center',marginTop: '1rem'}}>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        onClick={this.modalNuevaLista}
                      >
                        Nueva lista
                      </button>
                      </div> : 
                      <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        </Fragment>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.modalGenerica.isOpen,
  codigo_articulo: state.modalGenerica.codigo_articulo,
  lineas_carrito: state.modalGenerica.lineas,
  listas_compra: state.listasCompra.listas_compra,
  menus: state.portal.menus,
  portal: state.portal.portal,
  cookies: state.portal.cookies,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
});

export default connect(mapStateToProps, {
  postLineaLista,
  getListasCompra,
  postLineasLista,
  closeModal,
  crearLista,
})(ModalGenerica);
