import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import config from "../../config";
import { ordenarMenu } from "../../helpers/funciones";

import { MapPin, Phone, WhatsappLogo, EnvelopeSimple, FacebookLogo, InstagramLogo, TwitterLogo, LinkedinLogo } from "phosphor-react";

export class Footer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    const { portal } = this.props;
    let menus = this.props.menus;

    menus.sort(ordenarMenu);

    if (
      menus !== undefined &&
      menus.length > 0 &&
      this.props.auth.user !== null &&
      this.props.auth.user.puede_crear_usuarios !== "S"
    ) {
      menus = menus.filter((menu) => {
        return menu.programa !== "registroHijos";
      });
    }

    const enlaces_disponibles = (
      <Fragment>
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              menu.hijos.sort(ordenarMenu);
              return menu.visible === "S" &&
                menu.nombre !== "CARRITO" &&
                menu.nombre !== "USUARIO" ? (
                <Fragment key={menu.id}>
                  {menu.hijos.length > 0 ? (
                    <Fragment>
                      {menu.hijos.map((hijo) => {
                        return hijo.visible === "S" ? (
                          <li key={hijo.id}>
                            <Link
                              to={
                                "/" +
                                hijo.programa +
                                (hijo.camino !== null ? "/" + hijo.camino : "")
                              }
                            >
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </Fragment>
                  ) : (
                    <li key={menu.id}>
                      <Link
                        to={
                          "/" +
                          menu.programa +
                          (menu.camino !== null ? "/" + menu.camino : "")
                        }
                      >
                        {menu.nombre}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ) : (
                ""
              );
            })
          : ""}
        <li key={"configCookies"}>
          <Link to={"/configurarCookies"}>CONFIGURAR COOKIES</Link>
        </li>
      </Fragment>
    );

    const contenido = (
      <footer className="container contenedor-articulos">
        <div className="arriba">
          <div className="arriba-izq">
            <div className="empresa">
              {/* <img className="logo" src="/assets/img/placeholder.jpg" alt="Logo Monteverde" /> */}
              <div className="titulo">DISTRIBUCIONES MONTEVERDE, S.L.U.</div>
            </div>
            <div className="contacto">
              <div className="direccion">
                <MapPin color="#A68361" size={20} />
                <a href="https://g.page/dismonteverde?share" target={'_blank'}>
                  {config.contacto.direccion}<br/>
                  {config.contacto.parcela}<br/>
                  {config.contacto.localidad}
                </a>
              </div>
              <div className="telefono">
                <Phone color="#A68361" size={20} />
                <span>
                  {config.contacto.telefono}
                </span>
              </div>
              <div className="whatsapp">
                <WhatsappLogo color="#A68361" size={20} />
                <span>
                  {config.contacto.whatsapp}
                </span>
              </div>
              <div className="email">
                <EnvelopeSimple color="#A68361" size={20} />
                <span>
                  {config.contacto.email}
                </span>
              </div>
            </div>
            {/*
            <div className="horario">
              <div className="titulo">
                HORARIO ATENCIÓN AL PÚBLICO
              </div>
              <div className="texto">
                {config.contacto.horario}<br/>
                {config.contacto.horAm}<br/>
                {config.contacto.horPm}
              </div>
            </div>
            */}
          </div>
          <div className="arriba-drch">
            <div className="arriba-drch-arriba">
              <div className="redes-sociales">
                <div className="titulo">
                  SÍGUENOS
                </div>
                <ul className="lista-redes">
                  <li>
                    <a href={config.redes_sociales.facebook} target='_blank' rel="noreferrer">
                      <FacebookLogo color="#A68361" size={24} />
                    </a>
                  </li>
                  <li>
                    <a href={config.redes_sociales.instagram} target='_blank' rel="noreferrer">
                    <InstagramLogo color="#A68361" size={24} />
                    </a>
                  </li>
                  <li>
                    <a href={config.redes_sociales.linkedin} target='_blank' rel="noreferrer">
                      <LinkedinLogo color="#A68361" size={24} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="subscribete">
                <div className="horario">
                  <div className="titulo">
                    HORARIO ATENCIÓN AL PÚBLICO
                  </div>
                  <div className="texto">
                    {config.contacto.horario}<br/>
                    {config.contacto.horAm}<br/>
                    {config.contacto.horPm}
                  </div>
                </div>
                {/*
                <div className="titulo">
                  SUSCRÍBETE
                </div>
                <div className="cuerpo">
                  <div className="texto">
                    Descubre qué podemos ofrecerte, nuestros productos y servicios así como las novedades más recientes:
                  </div>
                  <form className="form-default">
                    <div className="input-group">
                      <input className="form-control" type="text" name="email" placeholder="Email" defaultValue="" />
                      <button type="submit" className="btn btn-primary">
                        Enviar
                      </button>
                    </div>
                    <div className="checkbox">
                      <input type="checkbox"/>
                      <label>{`He leido y acepto el `} &nbsp; <a href="https://dismonteverde.com">Aviso Legal</a> &nbsp; {` de este sitio Web`}</label>
                    </div>
                  </form>
                </div>
                */}
              </div>
            </div>
            {/*
            <div className="arriba-drcha-abajo">
              <div className="titulo">
                PRODUCTOS
              </div>
              <ul>
                <li>Lácteos</li>
                <li>Pato, oca y patés</li>
                <li>Precocinados</li>
                <li>Cocina</li>
                <li>Membrillos</li>
                <li>Conservas</li>
              </ul>
            </div>
            */}
          </div>
        </div>
        <div className="abajo">
          <div className="abajo-uno">
            <ul style={{"marginBottom": "0px"}}>
              <li>&copy;2022</li>
            </ul>
            <ul>
              <li><a href="https://www.dismonteverde.com/aviso-legal/" target="_blank" rel="noreferrer">Aviso legal</a></li>
              <li><a href="https://www.dismonteverde.com/politica-de-privacidad/" target="_blank" rel="noreferrer">Política de privacidad</a></li>
              <li><a href="https://www.dismonteverde.com/politica-de-privacidad-en-redes-sociales/" target="_blank" rel="noreferrer">Polícita de privacidad en redes sociales</a></li>
              <li><a href="https://www.dismonteverde.com/cookies/" target="_blank" rel="noreferrer">Política de cookies</a></li>
              <li><a href="https://www.dismonteverde.com/condiciones-generales-de-contratacion/" target="_blank" rel="noreferrer">Condiciones de uso</a></li>
              <li><a href="https://www.dismonteverde.com/informacion-sobre-las-garantias-en-al-venta-de-bienes-de-consumo/" target="_blank" rel="noreferrer">Garantías</a></li>
              <li><a href="https://www.dismonteverde.com/resolucion-de-litigios-en-linea/" target="_blank" rel="noreferrer">Resolución de litigios</a></li>
              <li><a href="https://www.dismonteverde.com/wp-content/uploads/2022/12/Solicitud_de_desistimiento.pdf" target="_blank" rel="noreferrer">Solicitud de desistimiento</a></li>
            </ul>
          </div>
          <div className="abajo-dos">
            <img id="xuntagalicia" src="/assets/img/xunta_de_galicia.svg" alt="Xunta de Galicia"/>
            <img id="unioneuropea" src="/assets/img/union_europea.svg" alt="Unión Europea"/>
            <img id="inega" src="/assets/img/inega.svg" alt="Inega"/>
            <img id="marcagalicia" src="/assets/img/marca_galicia.svg" alt="Marca Galicia"/>
            <img id="xacobeo" src="/assets/img/xacobeo21-22.svg" alt="Xacobeo"/>
            
          </div>
          <div className="abajo-dos">
            <img id="igape" src="/assets/img/igape.jpg" alt="Igape" style={{"height":"250px", "width": "auto"}}/>
            <img id="igape2" src="/assets/img/igape.png" alt="Igape" style={{"height":"210px", "width": "auto"}}/>
          </div>
        </div>
      </footer>
    );

    return (
      <Fragment>
        {config.footer.publico ? contenido : isAuthenticated ? contenido : ""}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  portal: state.portal.portal,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, { logout })(Footer);
